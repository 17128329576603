import React from "react";

const Premium = () => {
  return (
    <div>
      <h1 className="text-4xl">Premium</h1>
    </div>
  );
};

export default Premium;
