export const PromoImg = () => {
  return (
    <svg
      width="447"
      height="211"
      viewBox="0 0 447 211"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="w-full"
    >
      <path
        d="M446.909 145.727C446.909 122.682 428.227 104 405.182 104H205.818C182.773 104 164.091 122.682 164.091 145.727C164.091 168.773 182.773 187.455 205.818 187.455H361.909L387.409 210.636V187.455H405.182C428.227 187.455 446.909 168.773 446.909 145.727Z"
        fill="url(#paint0_linear_239_398)"
      ></path>
      <path
        d="M0 82.7273C0 59.6819 18.6819 41 41.7273 41H241.091C264.136 41 282.818 59.6819 282.818 82.7273C282.818 105.773 264.136 124.455 241.091 124.455H85L59.5 147.636V124.455H41.7273C18.6819 124.455 0 105.773 0 82.7273Z"
        fill="url(#paint1_linear_239_398)"
      ></path>
      <rect
        x="204.273"
        y="141.091"
        width="196.273"
        height="9.27273"
        rx="4.63636"
        fill="url(#paint2_linear_239_398)"
      ></rect>
      <rect
        width="196.273"
        height="9.27273"
        rx="4.63636"
        transform="matrix(-1 0 0 1 244.182 76.5455)"
        fill="url(#paint3_linear_239_398)"
      ></rect>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M388.86 122.72C404.799 122.72 417.72 109.799 417.72 93.86C417.72 77.921 404.799 65 388.86 65C372.921 65 360 77.921 360 93.86C360 109.799 372.921 122.72 388.86 122.72Z"
        fill="#00D659"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M381.287 92.9548L378.037 96.1912L386.693 103.472L400.885 88.2923L397.236 85.4345L386.279 97.1537L381.287 92.9548Z"
        fill="white"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M63.86 57.7199C79.7989 57.7199 92.7199 44.7989 92.7199 28.86C92.7199 12.921 79.7989 0 63.86 0C47.921 0 35 12.921 35 28.86C35 44.7989 47.921 57.7199 63.86 57.7199Z"
        fill="white"
      ></path>
      <path
        d="M62.7763 33.7823C62.5804 33.7823 62.4171 33.717 62.2865 33.5864C62.1559 33.4558 62.0906 33.3034 62.0906 33.1292C62.0906 32.6721 62.1015 32.3238 62.1232 32.0843C62.2321 31.0611 62.5586 30.1577 63.1029 29.374C63.6471 28.5903 64.3981 27.7196 65.356 26.7617C66.0961 26.0215 66.6404 25.412 66.9887 24.9331C67.3588 24.4542 67.5656 23.9535 67.6091 23.431C67.6962 22.5385 67.4023 21.831 66.7275 21.3085C66.0744 20.786 65.2798 20.5248 64.3437 20.5248C62.2321 20.5248 60.9586 21.5806 60.5232 23.6922C60.3708 24.1929 60.0552 24.4433 59.5762 24.4433H56.7354C56.5177 24.4433 56.3326 24.3671 56.1802 24.2147C56.0496 24.0623 55.9843 23.8664 55.9843 23.6269C56.0279 22.4296 56.387 21.3085 57.0619 20.2636C57.7585 19.2187 58.749 18.3805 60.0334 17.7492C61.3178 17.0961 62.8308 16.7696 64.5723 16.7696C66.3791 16.7696 67.8595 17.0635 69.0132 17.6513C70.1888 18.2173 71.0378 18.9465 71.5602 19.8391C72.1045 20.7316 72.3766 21.6568 72.3766 22.6147C72.3766 23.7031 72.1262 24.6501 71.6255 25.4555C71.1466 26.261 70.4282 27.1862 69.4704 28.2311C68.7738 28.9713 68.2295 29.6135 67.8377 30.1577C67.4676 30.6802 67.2173 31.2462 67.0866 31.8557C66.9996 32.3999 66.9451 32.77 66.9234 32.9659C66.8145 33.2489 66.6948 33.4558 66.5642 33.5864C66.4336 33.717 66.2485 33.7823 66.0091 33.7823H62.7763ZM62.6784 39.9539C62.4607 39.9539 62.2756 39.8777 62.1232 39.7253C61.9709 39.5729 61.8947 39.3879 61.8947 39.1702V36.264C61.8947 36.0463 61.9709 35.8613 62.1232 35.7089C62.2756 35.5565 62.4607 35.4803 62.6784 35.4803H65.9111C66.1288 35.4803 66.3138 35.5565 66.4662 35.7089C66.6404 35.8613 66.7275 36.0463 66.7275 36.264V39.1702C66.7275 39.3879 66.6513 39.5729 66.4989 39.7253C66.3465 39.8777 66.1506 39.9539 65.9111 39.9539H62.6784Z"
        fill="#6200EA"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_239_398"
          x1="446.909"
          y1="104"
          x2="164.091"
          y2="104"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B388FF"></stop>
          <stop offset="1" stop-color="white" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_239_398"
          x1="0"
          y1="41"
          x2="282.818"
          y2="41"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B388FF"></stop>
          <stop offset="1" stop-color="white" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_239_398"
          x1="400.545"
          y1="145.727"
          x2="204.273"
          y2="145.727"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white"></stop>
          <stop offset="1" stop-color="white" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_239_398"
          x1="196.273"
          y1="4.63636"
          x2="0"
          y2="4.63636"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white"></stop>
          <stop offset="1" stop-color="white" stop-opacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
