import React from "react";

const Privacy = () => {
  return (
    <div className="bg-white text-black">
      <section className="max-w-primary mx-auto px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
        {/* 1st section */}
        <h2 className="mb-10 text-xl">
          Privacy Policy of <span className="font-bold">mee6.xyz</span>
        </h2>
        <p className="text-xs">
          This Website collects some Personal Data from its Users.
        </p>
        <p className="my-5">
          This document can be printed for reference by using the print command
          in the settings of any browser.
        </p>
        <hr className="my-5" />
        {/* 2nd section */}
        <h2 className="font-bold">Owner and Data Controller</h2>
        <p className="text-xs">
          Sidescroll Ventures <br />
          66 Avenue des Champs-Élysées <br />
          Paris 75008 <br />
          France <br />
          Owner contact Email" Email:{" "}
          <a href="mailto:YQyQm@example.com">YQyQm@example.com</a>
        </p>
        <hr className="my-5" />
        {/* 3rd section */}
        <h2 className=" font-bold">Types of Data collected</h2>
        <p className="text-xs">
          Among the types of Personal Data that this Website collects, by itself
          or through third parties, there are: Cookies; Usage Data; username;
          picture. Complete details on each type of Personal Data collected are
          provided in the dedicated sections of this privacy policy or by
          specific explanation texts displayed prior to the Data collection.
          <br />
          <br />
          Personal Data may be freely provided by the User, or, in case of Usage
          Data, collected automatically when using this Website. Unless
          specified otherwise, all Data requested by this Website is mandatory
          and failure to provide this Data may make it impossible for this
          Website to provide its services. In cases where this Website
          specifically states that some Data is not mandatory, Users are free
          not to communicate this Data without consequences to the availability
          or the functioning of the Service. Users who are uncertain about which
          Personal Data is mandatory are welcome to contact the Owner.
          <br />
          <br />
          Any use of Cookies – or of other tracking tools — by this Website or
          by the owners of third-party services used by this Website serves the
          purpose of providing the Service required by the User, in addition to
          any other purposes described in the present document and in the Cookie
          Policy. Users are responsible for any third-party Personal Data
          obtained, published or shared through this Website.
        </p>
        <hr className="my-5" />
        {/* 4th section */}
        <h2 className="text-xl font-bold">
          Mode and place of processing the Data
        </h2>
        <br />
        <h3 className="text-sm font-bold">Methods of Processing</h3>
        <p className="text-xs">
          The Owner takes appropriate security measures to prevent unauthorized
          access, disclosure, modification, or unauthorized destruction of the
          Data. <br /> <br />
          The Data processing is carried out using computers and/or IT enabled
          tools, following organizational procedures and modes strictly related
          to the purposes indicated. <br /> In addition to the Owner, in some
          cases, the Data may be accessible to certain types of persons in
          charge, involved with the operation of this Website (administration,
          sales, marketing, legal, system administration) or external parties
          (such as third-party technical service providers, mail carriers,
          hosting providers, IT companies, communications agencies) appointed,
          if necessary, as Data Processors by the Owner. The updated list of
          these parties may be requested from the Owner at any time.
        </p>
        <br />
        <h3 className="font-bold">Place</h3>
        <p className="text-xs">
          The Data is processed at the Owner's operating offices and in any
          other places where the parties involved in the processing are located.
          <br />
          Depending on the User's location, data transfers may involve
          transferring the User's Data to a country other than their own. To
          find out more about the place of processing of such transferred Data,
          Users can check the section containing details about the processing of
          Personal Data.
        </p>
        <br />
        <h3 className="font-bold">Retention time</h3>
        <p className="text-xs">
          Unless specified otherwise in this document, Personal Data shall be
          processed and stored for as long as required by the purpose they have
          been collected for and may be retained for longer due to applicable
          legal obligation or based on the Users’ consent.
        </p>
        <hr className="my-5" />

        {/* 5th section */}
        <h2 className="font-bold">The purposes of processing</h2>
        <p className="text-xs">
          The Data concerning the User is collected to allow the Owner to
          provide its Service, comply with its legal obligations, respond to
          enforcement requests, protect its rights and interests (or those of
          its Users or third parties), detect any malicious or fraudulent
          activity, as well as the following: Analytics, Traffic optimization
          and distribution, Displaying content from external platforms,
          Infrastructure monitoring, Handling payments, Social features, Hosting
          and backend infrastructure and Registration and authentication. <br />{" "}
          For specific information about the Personal Data used for each
          purpose, the User may refer to the section “Detailed information on
          the processing of Personal Data”.
        </p>
        <hr className="my-5" />
        {/* 6th section */}
        <h2 className="font-bold">Cookie Policy</h2>
        <p className="text-xs">
          This Website uses Trackers. To learn more, Users may consult the
          Cookie Policy.
        </p>
        <hr className="my-5" />

        {/* 7th section */}
        <h2 className="text-xl font-bold">Further Information for Users</h2>
        <br />
        <h3 className=" font-bold">Legal basis of processing</h3>
        <p className="text-xs">
          In any case, the Owner will gladly help to clarify the specific legal
          basis that applies to the processing, and in particular whether the
          provision of Personal Data is a statutory or contractual requirement,
          or a requirement necessary to enter into a contract.
        </p>
        <br />
        <h3 className="font-bold">Further information about retention time</h3>
        <p className="text-xs">
          Unless specified otherwise in this document, Personal Data shall be
          processed and stored for as long as required by the purpose they have
          been collected for and may be retained for longer due to applicable.{" "}
          <br />
          legal obligation or based on the Users’ consent. The Owner may be
          allowed to retain Personal Data for a longer period whenever the User
          has given consent to such processing, as long as such consent is not
          withdrawn. <br /> Furthermore, the Owner may be obliged to retain
          Personal Data for a longer period whenever required to fulfil a legal
          obligation or upon order of an authority.
        </p>
        <hr className="my-5" />

        <h3 className="font-bold">
          Details about the right to object to processing
        </h3>
        <p className="text-xs">
          Where Personal Data is processed for a public interest, in the
          exercise of an official authority vested in the Owner or for the
          purposes of the legitimate interests pursued by the Owner, Users may
          object to such processing by providing a ground related to their
          particular situation to justify the objection. <br /> Users must know
          that, however, should their Personal Data be processed for direct
          marketing purposes, they can object to that processing at any time,
          free of charge and without providing any justification. Where the User
          objects to processing for direct marketing purposes, the Personal Data
          will no longer be processed for such purposes. <br /> To learn whether
          the Owner is processing Personal Data for direct marketing purposes,
          Users may refer to the relevant sections of this document.
        </p>
        <hr className="my-5" />
        <h4 className="text-xs font-bold">Latest update: September 08, 2020</h4>
        <p className="text-xs">
          iubenda hosts this content and only collects the Personal Data
          strictly necessary for it to be provided
        </p>
      </section>
    </div>
  );
};

export default Privacy;
