import React from "react";

const SocialAlerts = () => {
  return (
    <div>
      <h1 className="text-4xl">SocialAlerts</h1>
    </div>
  );
};

export default SocialAlerts;
